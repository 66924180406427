import axios from "axios";





export const TABS = [
  { display: "ओवरव्यू", page: "overview", url: "", keyToShow: "" },
  { display: "पॉइंट्स टेबल", page: "points-table", url: "points-table", keyToShow: "" },
  { display: "शेड्यूल", page: "matches", url: "schedule", keyToShow: "" },
  { display: "स्टैट्‍स", page: "stats", url: "stats", keyToShow: "ShowStats" },

]
export const SERIES_ID = 8322
export const APIS = {
  // matches : {
  //     live  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/livematch_list/hn_json/",
  //     upcoming :  "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8322_upcoming_hn_json/",
  //     recent  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8322_recent___hn_json/",
  // },
  matches: {
    live: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/livematch_list/hn_json/",
    upcoming: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8322_upcoming_hn_json/",
    recent: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8322_recent_hn_json/",
  }
  //   matches : {
  //     live  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/livematch_list/hn_json/123",
  //     upcoming :  "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8322_upcoming_hn_json/123",
  //     recent  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8322_recent_hn_json/123",
  // }
}

// 5970
// 8307
// export const SERIES_ID_IPL = 5970
export const SERIES_ID_IPL = 8307
// export const SERIES_ID_IPL = 10382


export const APISIPL = {
  // matches : {
  //     live  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/livematch_list/hn_json/",
  //     upcoming :  "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8322_upcoming_hn_json/",
  //     recent  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8322_recent___hn_json/",
  // },
  matches : {
      live  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/livematch_list/hn_json/",
      upcoming :  "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8307_upcoming_hn_json/",
      recent  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8307_recent_hn_json/",
      pointsTable : `https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_standings/8307_hn_json`,
      teams : 'https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/team_by_series/all_8307_1_10_hn_json/'
  }
//   matches : {
//     live  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/livematch_list/hn_json/",
//     upcoming :  "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/9093_upcoming_hn_json/",
//     recent  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/9093_recent_hn_json/",
//     pointsTable : `https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_standings/9093_hn_json`,
//     teams : 'https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/team_by_series/all_9093_1_10_hn_json/'
// }
//   matches : {
//     live  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/livematch_list/hn_json/",
//     upcoming :  "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/5970_upcoming_hn_json/",
//     recent  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/5970_recent_hn_json/",
//     pointsTable : `https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_standings/5970_hn_json`,
//     teams : 'https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/team_by_series/all_5970_1_10_hn_json/'
// }
//   matches : {
//     live  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/livematch_list/hn_json/123",
//     upcoming :  "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8322_upcoming_hn_json/123",
//     recent  : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/league_by_series_matchtype/8322_recent_hn_json/123",
// }
}
 
 	

export const  IPLTABS = [
  {displayName : "होम", 
  url : '/sports/ipl',
  id : "home",
  gaEvent : "Home_click"
},{displayName : "पॉइंट्स टेबल", 
  url : '/sports/ipl/points-table',
  id : "points-table",
  gaEvent : "Points Table_click"
},{displayName : "शेड्यूल", 
  url : '/sports/ipl/schedule',
  id : "matches",
  gaEvent : "Schedule_click"
},{displayName : "स्टैट्स", 
  url : '/sports/ipl/stats',
  id : "tracker",
  gaEvent : "IPL Tracker_click"
},{displayName : "ऑरेंज कैप", 
  url : '/sports/ipl/orange-cap-winner',
  id : "orange",
  gaEvent : "IPL Tracker_click"
},,{displayName : "पर्पल कैप", 
  url : '/sports/ipl/purple-cap-winner',
  id : "purple",
  gaEvent : "IPL Tracker_click"
},]

export const IPL_YEAR = 2024
export const IPL_DISPLAY_YEAR = 2025

export const goToMatchDetails = async (matchId , series) => {

  //fetch the status of the match
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL
    }/api/getRecentMatch?id=${matchId}&nocache=${new Date().getTime()}`
  );
  let fetchedData = response?.data ?? null;
  if (fetchedData?.data?.response?.scorecard?.match_Id?.length > 0) {
    const data = fetchedData?.data?.response?.scorecard;
    let page = null
    if (data.is_live == 1) {
      page = 'live'
    }
    else if (data.upcoming == 1) {
      page = 'info'
    }
    else {
      page = 'summary'
    }

    if(series == 'ipl'){
    window.location.href = `/sports/ipl/${data?.page_slug_st}/stats/${page}`
      
    }
    else if(series == 'ct'){
     window.location.href = `/sports/cricket/champions-trophy/${data?.page_slug_st}/stats/${page}`

    }

  }

}

export const getMatchDetails = async (matchId) => {

  //fetch the status of the match
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL
    }/api/getRecentMatch?id=${matchId}&nocache=${new Date().getTime()}`
  );
  let fetchedData = response?.data ?? null;
  if (fetchedData?.data?.response?.scorecard?.match_Id?.length > 0) {
    const data = fetchedData?.data?.response?.scorecard;
    return data

  }

}


//apis for tracker
export const IPL = {
  APIS: {
    orangeCap: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/5970_2_json/",
    purpleCap: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/5970_13_json/",
    // iplNumbers : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/5970_en_json/",
    iplNumbers: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_team_stats/5970_en_json/",
    mostDismissals: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/5970_21_json/",
    allStats: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/5970_0_json/"
  },
  APIS2025: {
    orangeCap: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/8307_2_json/",
    purpleCap: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/8307_13_json/",
    // iplNumbers : "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/8307_en_json/",
    iplNumbers: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_team_stats/8307_en_json/",
    mostDismissals: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/8307_21_json/",
    allStats: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/8307_0_json/"
  },
  APISCSR: {
    orangeCap: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/",
    purpleCap: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/",
    iplNumbers: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_team_stats/",
    mostDismissals: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/",
    allStats: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/"
  },
  ORANGE_CAP_YEARS : [
    { "year": "2025", "url": "orange-cap-winner" },
    { "year": "2024", "url": "orange-cap-winner-2024" },
    { "year": "2023", "url": "orange-cap-winner-2023" },
    { "year": "2022", "url": "orange-cap-winner-2022" },
    { "year": "2021", "url": "orange-cap-winner-2021" },
    { "year": "2020", "url": "orange-cap-winner-2020" },
    { "year": "2019", "url": "orange-cap-winner-2019" },
    { "year": "2018", "url": "orange-cap-winner-2018" }
  ],
  PURPLE_CAP_YEARS : [
    { "year": "2025", "url": "purple-cap-winner" },
    { "year": "2024", "url": "purple-cap-winner-2024" },
    { "year": "2023", "url": "purple-cap-winner-2023" },
    { "year": "2022", "url": "purple-cap-winner-2022" },
    { "year": "2021", "url": "purple-cap-winner-2021" },
    { "year": "2020", "url": "purple-cap-winner-2020" },
    { "year": "2019", "url": "purple-cap-winner-2019" },
    { "year": "2018", "url": "purple-cap-winner-2018" }
  ],
  YEARS2025: { '2025': "8307", '2024': '5970', '2023': '5157', '2022': '4623', '2021': '4165', '2020': '3978', '2019': '3130', '2018': '2940' },
  YEARS: { '2025': "8307", '2024': '5970', '2023': '5157', '2022': '4623', '2021': '4165', '2020': '3978', '2019': '3130', '2018': '2940' },
  DEFAULT_YEAR: '2024'
}



//apis for landing page
export const IPLLANDING = {
  APIS: {
    orangeCap: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/8307_2_json/",
    purpleCap: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/8307_13_json/",
    iplNumbers: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_team_stats/8307_en_json/",
    mostDismissals: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/8307_21_json/",
    allStats: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/8307_0_json/"
  },
  // APIS: {
  //   orangeCap: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/5970_2_json/",
  //   purpleCap: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/5970_13_json/",
  //   iplNumbers: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_team_stats/5970_en_json/",
  //   mostDismissals: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/5970_21_json/",
  //   allStats: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/5970_0_json/"
  // },
  APISCSR: {
    orangeCap: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/",
    purpleCap: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/",
    iplNumbers: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/",
    mostDismissals: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/",
    allStats: "https://sports-feeds.intoday.in/live-score/api_v1/lallantop_web/series_player_stats/"
  },
  YEARS: { '2025': '8307', '2024': '5970', '2023': '5157', '2022': '4623', '2021': '4165', '2020': '3978', '2019': '3130', '2018': '2940' },
  DEFAULT_YEAR: '2025'
}

//teams  of ipl
export const TEAMS = [
  { name: "CSK", logo: "https://static.thelallantop.com/images/post/1742205571781_15.webp" },
  { name: "DC", logo: "https://static.thelallantop.com/images/post/1742282146218_11.webp" },
  { name: "GT", logo: "https://static.thelallantop.com/images/post/1742282219923_16.webp" },
  { name: "KKR", logo: "https://static.thelallantop.com/images/post/1742282236086_17.webp" },
  { name: "LSG", logo: "https://static.thelallantop.com/images/post/1742282347027_18.webp" },
  { name: "MI", logo: "https://static.thelallantop.com/images/post/1742282365310_19.webp" },
  { name: "PBKS", logo: "https://static.thelallantop.com/images/post/1742285320809_20.webp" },
  { name: "RR", logo: "https://static.thelallantop.com/images/post/1742285364172_21.webp" },
  { name: "RCB", logo: "https://static.thelallantop.com/images/post/1742285377322_22.webp" },
  { name: "SRH", logo: "https://static.thelallantop.com/images/post/1742285394158_23.webp" }
];


export const TEAMS_OBJ = {
  "चेन्नई सुपर किंग्स": "https://static.thelallantop.com/images/post/1742889365535_15-min.webp",
  "चेन्नई": "https://static.thelallantop.com/images/post/1742889365535_15-min.webp",
  "Chennai": "https://static.thelallantop.com/images/post/1742889365535_15-min.webp",
  "CSK": "https://static.thelallantop.com/images/post/1742889365535_15-min.webp",
  
  "दिल्ली कैपिटल्स": "https://static.thelallantop.com/images/post/1742889348872_11-min.webp",
  "दिल्ली": "https://static.thelallantop.com/images/post/1742889348872_11-min.webp",
  "Delhi": "https://static.thelallantop.com/images/post/1742889348872_11-min.webp",
  "DC": "https://static.thelallantop.com/images/post/1742889348872_11-min.webp",
  
  "गुजरात टाइटन्स": "https://static.thelallantop.com/images/post/1742889380124_16-min.webp",
  "गुजरात": "https://static.thelallantop.com/images/post/1742889380124_16-min.webp",
  "Gujarat": "https://static.thelallantop.com/images/post/1742889380124_16-min.webp",
  "GT": "https://static.thelallantop.com/images/post/1742889380124_16-min.webp",
  
  "कोलकाता नाइट राइडर्स": "https://static.thelallantop.com/images/post/1742889392428_17-min.webp",
  "कोलकाता": "https://static.thelallantop.com/images/post/1742889392428_17-min.webp",
  "Kolkata": "https://static.thelallantop.com/images/post/1742889392428_17-min.webp",
  "KKR": "https://static.thelallantop.com/images/post/1742889392428_17-min.webp",
  
  "लखनऊ सुपर जायंट्स": "https://static.thelallantop.com/images/post/1742889403447_18-min.webp",
  "लखनऊ": "https://static.thelallantop.com/images/post/1742889403447_18-min.webp",
  "Lucknow": "https://static.thelallantop.com/images/post/1742889403447_18-min.webp",
  "LSG": "https://static.thelallantop.com/images/post/1742889403447_18-min.webp",
  
  "मुंबई इंडियंस": "https://static.thelallantop.com/images/post/1742889442176_19-min.webp",
  "मुंबई": "https://static.thelallantop.com/images/post/1742889442176_19-min.webp",
  "Mumbai": "https://static.thelallantop.com/images/post/1742889442176_19-min.webp",
  "MI": "https://static.thelallantop.com/images/post/1742889442176_19-min.webp",
  
  "पंजाब किंग्स": "https://static.thelallantop.com/images/post/1742889453210_20-min.webp",
  "पंजाब": "https://static.thelallantop.com/images/post/1742889453210_20-min.webp",
  "Punjab": "https://static.thelallantop.com/images/post/1742889453210_20-min.webp",
  "PBKS": "https://static.thelallantop.com/images/post/1742889453210_20-min.webp",
  
  "राजस्थान रॉयल्स": "https://static.thelallantop.com/images/post/1742889467544_21-min.webp",
  "राजस्थान": "https://static.thelallantop.com/images/post/1742889467544_21-min.webp",
  "Rajasthan": "https://static.thelallantop.com/images/post/1742889467544_21-min.webp",
  "RR": "https://static.thelallantop.com/images/post/1742889467544_21-min.webp",
  
  "रॉयल चैलेंजर्स बेंगलुरु": "https://static.thelallantop.com/images/post/1742889480923_22-min.webp",
  "बेंगलुरु": "https://static.thelallantop.com/images/post/1742889480923_22-min.webp",
  "Bangalore": "https://static.thelallantop.com/images/post/1742889480923_22-min.webp",
  "RCB": "https://static.thelallantop.com/images/post/1742889480923_22-min.webp",
  
  "सनराइजर्स हैदराबाद": "https://static.thelallantop.com/images/post/1742889496163_23-min.webp",
  "सनराइज़र्स हैदराबाद" : "https://static.thelallantop.com/images/post/1742889496163_23-min.webp",
  "हैदराबाद": "https://static.thelallantop.com/images/post/1742889496163_23-min.webp",
  "Hyderabad": "https://static.thelallantop.com/images/post/1742889496163_23-min.webp",
  "SRH": "https://static.thelallantop.com/images/post/1742889496163_23-min.webp"
};


// export const VS_LINK = 'https://vsfeed.intoday.in/visualstories/feed/api.php?site=lallantop&type=hp&start=0&end=10'
export const VS_LINK = 'https://vsfeed.intoday.in/visualstories/feed/api.php?site=lallantop&type=category&cat=sports&start=0&end=10'

export const VALID_YEARS =  ["2024", "2023", "2022", "2021", "2020", "2019", "2018"];
export const VALID_CATEGORIES = ["orange-cap-winner", "purple-cap-winner"];


/**
* Sends an event to Google Analytics.
*
* @param {object} params - The event parameters.
* @param {string} params.eventName - The name of the event.
* @param {string} params.pageTitle - The title of the page where the event occurred.
* @param {string} params.pageLocation - The full URL of the page where the event occurred.
* @param {string} params.searchQuery - (Optional) The full URL of the page where the event occurred.
* @param {object} patams.object - Any other extra object along with the events.
*/
function sendGAEvent({
  eventName = "",
  pageTitle = "",
  pageLocation = "",
  propertyObject = {},
  searchQuery = undefined,
}) {
  // Check if gtag is defined on the window object
  const GACode = process.env.NEXT_PUBLIC_GACODE ?? "";
  if (
    typeof window.gtag === "function" &&
    eventName &&
    pageTitle &&
    pageLocation
  ) {
    // console.log("GA event SENT", {
    //   eventName,
    //   page_title: pageTitle,
    //   page_location: pageLocation,
    //   sent_to: GACode,
    // });
    let obj = {
      page_title: pageTitle,
      page_location: pageLocation,
      send_to: GACode,
      ...propertyObject
    };
    if (searchQuery) {
      obj.search_query = searchQuery;
    }
    window.gtag("event", eventName, obj);
  } else {
    console.warn("gtag is not defined. Unable to send event to GA.");
  }
}
// export default sendGAEvent;

export const callGaEvent = ({ eventName, pageTitle, propertyObject = {} }) => {
  console.log("the event is called");
  sendGAEvent({
    eventName: eventName, pageTitle: pageTitle,
    pageLocation: window?.location?.href, propertyObject
  })
}